
.hero-background {
    min-height: 100vh;
    background-size: cover;
    background-position: 100% 75%;
    background-repeat: no-repeat;
    position: fixed;
    /* filter: blur(2px); */
}

.hero {
    background: transparent;
    min-height: 100vh;
    margin: 0;
    position: relative;
    z-index: 2;
}

.hero .content-container {
    display: flex;
    padding: 15vh 10vh;
    min-height: 100vh;
}
.hero .content-container .left-side {
    height: 435px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;

}

.hero h1 {
    font-weight: bold;
}

.hero .logo img {
    width: 90px;
    height: 90px;
}

.hero h5 {
    font-size: 1.75rem;
}

.hero .left-side .title-container {
    width: 88%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hero .right-side {
    position: relative;
    overflow-y: scroll;
}

.hero .right-side[data-page="Login"] {
    min-height: 435px;
}

.hero .right-side[data-page="Forgot"] {
    height: 435px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.right-side::-webkit-scrollbar {
	display: none;
}

.slogan {
    /* margin-top: 25%; */
    text-align: justify;
    font-family: 'PT Sans', sans-serif;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.slogan p {
    font-size: 1.7rem;
    margin-bottom: 0;
    font-weight: 300;
    line-height: normal;
}

.data-container {
    padding: 5vh 25vh;
    width: 100%;
    color: #fff;
}

.mr-3 {
    margin-right: 15px !important;
}

.border {
    border: 1px solid #fff;
}

.logo-title {
    display: none;
}

.header {
    display: flex; 
    justify-content: space-evenly; 
    font-size: 100%;
    padding-top: 20px;
    padding-right: 50px;
}

.login {
    height: 35px;
    margin-top: 15px;
    padding: 5px 40px 5px 40px;
    cursor: pointer;
    color: white;
    background: #3f51b5;
    border-radius: 50px
}

.login2 {
    display: none;
}

.logo2 {
    display: none;
}

@media (max-width: 991px) {
    .hero .content-container {
        padding: 5vh 5vh;
        min-height: 60vh;
        text-align: center;
    }

    .hero h1 {
        font-size: 1.7rem;
    }

    .hero h5 {
        font-size: 1.5rem;
    }

    .slogan p {
        font-size: 1.3rem;
    }

    .hero .content-container .left-side {
        align-self: center;

    }

    .hero .left-side .title-container {
        position: relative;
        width: 85%;
    }

    .hero .right-side {
        position: relative;
        align-self: center;
    }

    .slogan {
        margin-top: 5vh;
        text-align: justify;
    }

    .data-container {
        padding: 0 5vh;
    }
}

@media (max-width: 767px) {
    .hero .content-container {
        display:block;
        padding: 20px;
    }

    .logo {
        display: none;
    }

    .logo2 {
        display: block;
    }

    .login {
        display: none;
    }

    .login2 {
        display: block;
        height: 35px;
        padding: 5px 40px 5px 40px;
        cursor: pointer;
        color: white;
        background: #3f51b5;
        border-radius: 50px
    }

    .header {
        padding-top: 5px;
        padding-right: 5px;
    }

    .main-container[data-page="Login"] {
        height: fit-content;
    }

    .hero .right-side[data-page="Login"], .hero .right-side[data-page="Register"], .hero .right-side[data-page="Forgot"] {
        height: fit-content;
    }

    .hero .right-side[data-page="Forgot"] {
        justify-content: flex-start;
    }

    .hero .left-side[data-page="Login"], .hero .left-side[data-page="Register"] , .hero .left-side[data-page="Forgot"] {
        display: none;
    }

    .title {
        font-size: 50%;
    }

    .slogan {
        text-align: justify;
    }

    .deskripsi {
        width: 70%;
    }

    .data-container {
        padding: 0;
        flex-direction: column;
    }

    .logo {
        margin-right: 10px;
    }

    .logo-title {
        display: flex;
        color: #fff;
        justify-content: center;
    }

    .logo-title .logo img {
        width: 75px;
        height: 75px;
    }

    .logo-title .deskripsi {
        margin-left: 10px;
    }

    .logo-title h2 {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
    }
    
}