.footer {
    /* background: rgba(255, 255, 255, 1); */
    background: #00000000;
    /* padding: 10px 13vh; */
    /* padding-top: 5px; */
    padding-bottom: 8px;
    z-index: 99999;
    position: absolute;
}

.footer-list {
    flex-wrap: wrap;
    align-self: center;
    margin-bottom: 0;
    justify-content: center;

}

.footer-list li {
    margin: 0px 10px;
    min-width: max-content;
}

.footer-link {
    /* color: #505254; */
    color: white;
    font-size: 13px;
    text-decoration: none;
}

.footer-link:hover {
    /* color: #505254; */
    color: white;
    font-weight: bold;
    text-decoration: underline;
}

.copyright {
    font-size: 13px;
    text-align: center;
    /* color: #505254; */
    color: white;
    font-weight: bold;
    justify-content: center;

}

@media (max-width: 991px) {
    .footer-list {
        justify-content: center;
        min-width: max-content;
    }

    .copyright {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .footer {
        display: none;
    }

    .footer-list {
        justify-content: center;
        flex-wrap: wrap;
        min-width: max-content;
    }

    .footer-list .footer-link {
        font-size: 12px;
        min-width: max-content;
    }

    .footer-list li {
        text-align: center;
        min-width: max-content;
    }

    .copyright {
        font-size: 11px;
        justify-content: center;
    }

    .footer .link-list {
        display: none !important;
    }
}