.data-container {
    padding: 5vh 25vh;
    width: 100%;
    color: #fff;
}

.sub-tittle {
    padding-left: 100px;
    padding-right: 100px;
}

#desktop {
    display: block;
}
#bg-mobile {
    display: none;
}

@media  (max-width: 767px) {

    #desktop {
        display: none;
    }
    #bg-mobile {
        display: block;
    }

    .sub-tittle {
        font-size: x-small;
        padding-left: 5px;
        padding-right: 5px;
    }
}
